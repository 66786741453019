import { useState } from 'react';
import { Email, CheckCircle } from '@mui/icons-material';
import { Button, Typography, Textarea, Alert, Grid, Stack } from '@mui/joy';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/user';

const Contact = () => {
  const { t } = useTranslation();
  const [emailContent, setEmailContent] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { user } = useUser();

  let formContent;
  if (emailSent) {
    formContent = (
      <Alert color="success" sx={{ mt: 8 }} startDecorator={<CheckCircle />}>
        {t('contact.thankYouMessage', { firstName: user.firstName })}
      </Alert>
    );
  } else {
    formContent = (
      <Stack spacing={2}>
        <Typography level="body-lg">{t('contact.promptMessage')}</Typography>

        <Textarea
          value={emailContent}
          minRows={8}
          size="lg"
          onChange={evt => setEmailContent(evt.target.value)}
          aria-label="Contact message"
        />

        <Button
          loading={sendingEmail}
          disabled={emailContent.length === 0}
          onClick={async () => {
            setSendingEmail(true);
            const sendEmail = httpsCallable(getFunctions(), 'email');
            await sendEmail({
              to: 'info@elfs.org.au',
              subject: 'ELFS Support Request',
              body: `<p>Hi team,</p>
                     <p>You received a support request email from ${user.firstName} ${user.lastName}.<br/>
                     Email: ${user.email}<br/>
                     Study ID: ${user.redcapId}<br/>
                     Phone number: ${user.phoneNumber}</p>
                     <p>--------<br/>${emailContent}<br/>--------</p>
                     <p>The ELFS app</p>`
            });
            setSendingEmail(false);
            setEmailSent(true);
          }}
        >
          {t('contact.submitButton')}
        </Button>
      </Stack>
    );
  }

  return (
    <Grid container spacing={3} sx={{ my: 8, mx: 3 }} justifyContent="center">
      <Grid xs={12} sm={9} md={6}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography level="h3" startDecorator={<Email />}>
              {t('contact.title')}
            </Typography>
          </Stack>
          {formContent}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Contact;
